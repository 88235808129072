import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import rehypeReact from "rehype-react";

import { Button } from "gatsby-theme-material-ui";
import { Box } from "@material-ui/core";
import { GatsbyImage } from "gatsby-plugin-image";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Layout from "../components/Layout";
import Container from "../components/Container";
import Seo from "../components/SEO";

{
  /* import { Link } from "gatsby-theme-material-ui";
import Scene from "../assets/videos/enjoy.webm"; */
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2em",
    maxWidth: 654,
  },
  overline: {
    textAlign: "justify",
    marginTop: "1em",
    fontWeight: "bold",
    marginBottom: "1em",
  },
  body: {
    textAlign: "justify",
  },
  what: {
    marginTop: "1em",
    marginBottom: "1em",
    fontWeight: "600",
    color: "#F45A28",
  },
  heading: {
    fontVariant: "small-caps",
    marginBottom: "1em",
    fontWeight: "900",
  },
  compactList: {
    paddingLeft: theme.spacing(1),
    listStyleType: "none",
  },
  compactListItem: {
    marginBottom: 0,
    padding: `${theme.spacing(0.5)}px 0`,
    // fontSize: "0.875rem", // equivalent to theme.typography.body2.fontSize
    position: "relative",
    paddingLeft: 40, // Make space for the custom symbol
    "&::before": {
      content: '"🍇"', // Unicode arrow symbol
      position: "absolute",
      left: 10,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  action: {
    display: "flex",
    justifyContent: "center",
    whiteSpace: "nowrap",
    maxWidth: "220px",
    minWidth: "220px",
    paddingBottom: "5em",
  },
  rightGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headText: {
    position: "relative",
    textAlign: "center",
    paddingTop: "25px",
    height: "200px",
  },
  headImage: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  blurb: {
    maxWidth: 654,
    marginTop: 30,
  },
  video: {
    maxWidth: "40%",
    float: "right",
    margin: 20,
  },
}));

const GroupTripsPage = ({
  data: {
    allMarkdownRemark: { edges: content },
  },
}) => {
  const styles = useStyles();
  return (
    <Layout pageName="grouptrips">
      <Helmet>
        <title>Group Tours from San Diego</title>
      </Helmet>
      <Container maxWidth="md">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid className={styles.blurb}>
            <Typography
              variant="body1"
              align="justify"
              component="div"
            >
              We here at Valle Guadalupe Baja are excited to help guide you on
              the best way to enjoy Valle de Guadalupe and Ramona California.
              Please see below our 2023-2024 Group Tour options from the San
              Diego border to Valle de Guadalupe and the Baja Coast as well as
              in San Diego's nearby Ramona Wine Region.
              <br />
              {
                /*
              <Box className={styles.video}>
                <video width="100%" controls autoPlay muted loop>
                  <source src={Scene} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              </Box>
              */
              }
              <br />
              We have {content.length} day trip style tours to choose from:
              <ul className={styles.compactList}>
                <li className={styles.compactListItem}>
                  <b>Best Seller Tour</b> of Baja California
                </li>
                <li className={styles.compactListItem}>
                  <b>Trending Hot Spots</b> of Valle de Guadalupe
                </li>
                <li className={styles.compactListItem}>
                  Local's Favorites in <b>Ramona California</b>
                </li>
              </ul>
              We can't wait to see you on the tour that appeals to you most!
              Come collect memories!

              <hr />
            </Typography>
          </Grid>
          <Grid
            container
            maxWidth="md"
            className={styles.root}
            spacing={4}
          >
            {content.map(
              ({ node: { frontmatter: c, htmlAst } }) => (
                <>
                  <Seo event={c} />
                  {/* xs, sm, md, lg, and xl. */}
                  <Container>
                    <Typography
                      className={styles.heading}
                      variant="h4"
                      align="center"
                      component="h4"
                      color="primary"
                    >
                      {c.title}
                    </Typography>
                  </Container>
                  <Container maxWidth="md">
                    <a href={c.url} alt={c.title}>
                      <GatsbyImage
                        alt={c.title}
                        image={c.image.childImageSharp
                          .gatsbyImageData}
                      />
                    </a>
                  </Container>
                  <Typography
                    className={styles.overline}
                    variant="caption"
                    align="justify"
                    color="initial"
                  >
                    {c.overline}
                  </Typography>
                  <Container>
                    <Typography
                      className={styles.what}
                      variant="h5"
                      align="center"
                      component="h5"
                    >
                      {"What's Included?"}
                    </Typography>
                  </Container>
                  <Typography
                    className={styles.body}
                    variant="body1"
                    align="justify"
                    color="initial"
                  >
                    {renderAst(htmlAst)}
                  </Typography>
                  <Container className={styles.action}>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      to={c.url}
                    >
                      {c.buttonText}
                    </Button>
                  </Container>
                </>
              ),
            )}
          </Grid>
          <Grid className={styles.blurb}>
            <hr/>
            <Typography
              variant="body2"
              align="justify"
              component="div"
            >
              Any of our Baja tours can be made into an overnight tour. 
              Stay in the stunning Valle de Guadalupe and travel back to
              the border the next morning after breakfast. Please email
              Aimee@ValleGuadalupeBaja.com for information!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default GroupTripsPage;

export const GroupTripsPageQuery = graphql`
    query GroupTripsPageQuery {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "grouptrips" } } }
            sort: { fields: frontmatter___idx, order: ASC }
        ) {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        buttonText
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                        jsonld {
                          dates
                        }
                        overline
                        title
                        url
                        location
                        address
                        priceLow
                        priceHigh
                    }
                }
            }
        }
    }
`;
