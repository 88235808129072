import React from "react";
import { Helmet } from "react-helmet";
import { getSrc } from "gatsby-plugin-image";

const subEvent = (event, date) => (
  {
    "@type": "Event",
    "name": event.title,
    "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
    "startDate": date[0],
    "endDate": date[1],
    "location": {
      "@type": "Place",
      "address": event.address,
      "name": event.location,
    },
    "offers": [{
      "@type": "Offer",
      "validFrom": "2023-09-01T12:00",
      "priceCurrency": "USD",
      "lowPrice": event.priceLow,
      "highPrice": event.priceHigh,
      "availability": "http://schema.org/PreOrder",
      "validThrough": date[0],
      "availabilityEnds": date[0],
      "url": event.url,
      "areaServed": {
        "@type": "City",
        "name": "San Diego",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "San Diego",
          "addressRegion": "CA",
          "addressCountry": "US",
        },
      },
    }],
  }
);

const Seo = ({ event }) => (
  event.jsonld?.dates && (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Event",
          "name": event.title,
          "description": event.overline,
          "startDate": event.jsonld.dates[0][0],
          "endDate": event.jsonld.dates[event.jsonld.dates.length - 1][1],
          "url": event.url,
          "image": getSrc(event.image.childImageSharp),
          "eventAttendanceMode":
            "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": {
            "@type": "Place",
            "address": event.address,
            "name": event.location,
          },
          "organizer": {
            "url":
              "https://www.eventbrite.com/o/valle-guadalupe-baja-42558014253",
            "description":
              "Valle Guadalupe Baja is a travel website that helps people from Southern California experience Baja's wine country in refreshing and stylish ways. We hope you Visit. Enjoy. and Remember. We are here for you!https://www.valleguadalupebaja.com/Aimee@ValleGuadalupeBaja.comWhats App: 619-361-8571Instagram: @ValleGuadalupeBaja",
            "@type": "Organization",
            "name": "Valle Guadalupe Baja",
          },
          "subEvent": event.jsonld.dates.map((d) => subEvent(event, d)),
        })}
      </script>
    </Helmet>
  )
);

export default Seo;
